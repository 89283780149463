@font-face {
  font-family: 'Hero-Bold';
  src:  local('Hero-Bold'),
        url(./fonts/Hero-Bold.otf) format('opentype'),
        url(./fonts/Hero-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Hero-Light';
  src:  local('Hero-Light'),
        url(./fonts/Hero-Light.otf) format('opentype'),
        url(./fonts/Hero-Light.ttf) format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Hero-Regular';
  src:  local('Hero-Regular'),
        url(./fonts/Hero-Regular.otf) format('opentype'),
        url(./fonts/Hero-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
