:root {
  --black: #000;
  --white: #fff;
  --blue: #C0E8EA;
  --pink: #F5B8B9;
  --off-white: #F0FAFE;
  --hover-brightness: 1.1;
  --grey: #515E65;
}

body {
  background-color: var(--off-white);
  font-family: 'Josefin Sans', sans-serif;
  margin: 0;
  padding: 0;
}

header {
  text-align: center;
  background-color: var(--white);
}

.callout {
  background-color: var(--blue);
  padding: 0.1rem 1rem;
}

header img {
  width: 260px;
  margin: 1rem 0 0.6rem 0;
}

.heading {
  text-align: center;
}

h1 {
  font-size: 4.688rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  color: var(--grey);
  margin: 1.5rem 0 0.5rem 0;
}

p {
  font-size: 1.2rem;
  color: var(--grey);
}

img {
  max-width: 100%;
}

.bold {
  font-weight: bold;
}

.form-container {
  padding: 0 1rem;
}

form {
  max-width: 600px;
}

label {
  display: block;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: var(--grey);
}

.sub-label {
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  color: var(--grey);
}

select {
  width: 100%;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.4rem;
  color: var(--grey);
  margin: 0;
  display: block;
  box-sizing: border-box;
  line-height: 1.3;
  padding: .6rem 1.4rem .5rem .8rem;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.step {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.button {
  border: solid 2px var(--pink);
  background-color: var(--pink);
  color: var(--white);
  border-radius: 20px;
  padding: 0.6rem 1rem 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.1rem;
  cursor: pointer;
  margin: 0 1rem 1rem 0;
}

.button--blue {
  border: solid 2px var(--blue);
  background-color: var(--blue);
  color: var(--white);
}

.button:hover {
  filter: brightness(var(--hover-brightness));
}

.button.bottom-margin-zero {
  margin-bottom: 0;
}

.buttons {
  margin-bottom: 1rem;
}

.link {
  color: var(--grey);
  text-decoration: underline;
}

.link--large {
  font-size: 1.2rem;
}

.link--white {
  color: var(--white);
  text-decoration: none;
}

.link:hover{
  font-weight: bold;
}

.back-icon {
  height: 1.4rem;
  width: auto;
  padding: 0 0.6rem;
  margin: 0;
  vertical-align: middle;
  /* display: inline-block; */
}

.filter-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(144deg) brightness(101%) contrast(104%);
}

.fadeOut{
  opacity:0;
  transition: opacity 0.2s;
}

.fadeIn{
  opacity:1;
  transition: opacity 0.5s;
}

.social-box {
  display: flex;
  align-items:center;
}

.social-icon {
  height: 44px;
  width: auto;
  margin: 6px 10px 6px 0;
}

.recommended-size {
  font-size: 1.4rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
}

.CookieConsent {
  font-family: Helvetica, sans-serif;
}

.CookieConsent .link {
  color: var(--black);
  text-decoration: underline;
}

.ccContent {
  margin: 0.5rem;
}

#rcc-decline-button {
  background-color: var(--white);
  color: var(--black);
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border: solid 1px var(--black);
}

#rcc-confirm-button {
  background-color: var(--black);
  color: var(--white);
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border: solid 1px var(--black);
}
